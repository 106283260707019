<template>
  <div class="app-wrapper" style="height: 100vh">
    <div v-if="mileage" class="tip_info">已根据行驶里程推荐
      <span>{{recommandedPlanYear}}/{{recommandedPlanMileage/10000}}万公里</span> 保养计划</div>
    <div v-else class="tip_info">完善行驶里程后，将自动推荐保养计划</div>
    <el-table ref="table" class="maintenance-table" :data="tableData" style="width: 100%" :height="tableHeight" :border="true" empty-text="" @header-click="onHeaderClicked" @cell-click="onCellClicked">
      <el-table-column fixed prop="name" label="保养项目" :width="ratioSize(181)" header-align="center" align="center">
        <template slot-scope="scope">
          <span :class="{'long-text': scope.row.name.length > 5}">{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column :class-name="columnClass(data)" v-for="(data, index) in maintenancePlan.mileageList" :key="index" :prop="`mileage-${data.id}`" :render-header="renderHeader" :label="`${data.month/12}年 ${data.mileage/10000}万公里`" header-align="center" align="center" :width="ratioSize(132)" :column-key="`${data.id}`">
        <template slot-scope="scope">
          <span class="maintenance-item" :class="cellClass(scope.row, data.id)" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      checkedPlanId: 0,
      selectedServices: []
    }
  },
  methods: {
    columnClass (data) {
      if (this.checkedPlanId) {
        return this.checkedPlanId === data.id ? 'column-recommanded' : ''
      } else {
        return (this.planRecommanded(data.id) ? 'column-recommanded' : '')
      }
    },
    cellClass (rowData, columnId) {
      return [{'matched': this.itemMatched(rowData, columnId)}, {'recommanded': this.planRecommanded(columnId)}, {'selected': this.itemSelected(rowData, columnId)}]
    },
    planRecommanded (id) {
      if (this.checkedPlanId) {
        return id === this.checkedPlanId
      } else {
        return id === this.recommandedPlanId
      }
    },
    renderHeader (h, columnData) {
      const data = this.maintenancePlan.mileageList[columnData.$index - 1]
      return h('div', {
        'class': {
          'header-column': true
        }
      }, [
        h('p', `${data.month / 12}年`),
        h('p', `${data.mileage / 10000}万公里`)]
      )
    },
    doSelectServices (planId) {
      this.checkedPlanId = planId
      let ls = []
      this.tableData.forEach(element => {
        if (this.itemMatched(element, planId)) {
          ls.push(this.maintenancePlan.maintenanceVOList[element.index])
        }
      })
      this.$nextTick(() => {
        this.selectedServices = ls
        this.$emit('selected-services-changed', this.selectedServices)
      })
    },
    itemMatched (row, key) {
      return row[`mileage-${key}`] === 1
    },
    itemSelected (row, columnId) {
      if (!this.selectedServices || this.checkedPlanId !== parseInt(columnId)) {
        return false
      }

      let found = false
      for (let service of this.selectedServices) {
        if (service.maintenance.id === row.id) {
          found = true
          break
        }
      }

      return found
    },
    onHeaderClicked (column, event) {
      this.doSelectServices(parseInt(column.columnKey))
    },
    onCellClicked (row, column, cell, event) {
      if (parseInt(column.columnKey) !== this.checkedPlanId) {
        return
      }

      let index = 0
      let found = false
      let ls = Object.assign([], this.selectedServices)
      ls.forEach(element => {
        if (element.maintenance.id === this.maintenancePlan.maintenanceVOList[row.index].maintenance.id) {
          ls.splice(index, 1)
          found = true
        }

        index++
      })

      if (!found) {
        ls.push(this.maintenancePlan.maintenanceVOList[row.index])
      }

      this.$nextTick(() => {
        this.selectedServices = ls
        this.$emit('selected-services-changed', this.selectedServices)
      })
    },
    onPlanUpdated () {
      this.$nextTick(() => {
        this.$refs.table.bodyWrapper.scrollLeft = this.sizeRatio * 132 * (this.recommandedPlanIndex > 0 ? this.recommandedPlanIndex - 1 : this.recommandedPlanIndex)
      })

      this.doSelectServices(this.recommandedPlanId)
    }
  },
  watch: {
    mileage () {
      this.onPlanUpdated()
    }
  },
  beforeMount () {
    let height = document.documentElement.clientHeight || document.body.clientHeight
    this.sizeRatio = (document.documentElement.clientWidth || document.body.clientWidth) / 750
    this.tableHeight = height - 56 * this.sizeRatio
  },
  mounted () {
    this.$on('plan-updated', () => {
      setTimeout(() => {
        this.onPlanUpdated()
      }, 100)
    })

    this.$on('selected-services-changed', () => {
      console.log('selected-services-changed')
      console.log(JSON.stringify(this.selectedServices))
    })
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index";
.wrapper {
  border-radius: 10px;
}

.tip_info {
  height: px2rem(56px);
  background: #fef9f3;
  font-size: px2rem(22px);
  line-height: px2rem(56px);
  color: #4081d6;
  font-weight: 500;
  text-align: center;
  border-bottom: px2rem(1px) solid #dddddd;

  span {
    color: #fc4c5a;
  }
}

.maintenance-item {
  display: inline-block;
  width: px2rem(30px);
  height: px2rem(30px);
  &.recommanded {
    background-image: url(~images/garage/ticked_grey.png);
    background-size: px2rem(30px) px2rem(30px);

    &.selected {
      background-image: url(~images/garage/ticked.png);
    }
  }
}

.matched {
    &::before {
    content: '';
    width: 0; 
    height: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-top: px2rem(24px) solid #4081d6;
    border-right: px2rem(24px) solid transparent;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.recommanded::before {
    border-top: px2rem(24px) solid #fc4c5a;
    border-right: px2rem(24px) solid transparent;
  }
}
</style>
